<template>
    <v-container fluid class="py-0 px-0" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
        >
            <v-tab key="projects" :to="{ name: 'projects' }">
                PROJECTS
            </v-tab>
            <v-tab
                key="generic"
                :to="{
                    name: 'projects-generic',
                }"
            >
                GENERIC
            </v-tab>
            <v-tab
                v-if="tabClosing"
                key="closing"
                :to="{
                    name: 'closing-projects',
                }"
            >
                CLOSING
            </v-tab>
        </v-tabs>
        <v-card flat>
            <v-card-text
                class="overflow-y-auto px-4 pt-4"
                :style="`height: ${height}px`"
            >
                <router-view />
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import API from '@/services/api'
export default {
    name: 'ProjectTabs',
    data: () => ({
        loading: true,
        height: 0,
        userRef: {},
        tabClosing: true,
    }),
    async mounted() {
        try {
            this.loading = true
            const {
                data: { user },
            } = await API.getMyInfo()
            this.userRef = user
            if (
                !this.userRef.permissions.includes('archiveProject') &&
                !this.userRef.permissions.includes('comexEvidence') &&
                !this.userRef.permissions.includes('portfolioEvidence') &&
                !this.userRef.permissions.includes('sapEvidence')
            ) {
                this.tabClosing = false
            }
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
            } = this.$refs
            this.height = window.innerHeight - containerHeight - tabsHeight - 25
        },
    },
}
</script>

<style></style>
